import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/" element={<General />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

function Portfolio() {
  return (
    <div className="ContentWrapper">
      <div className="ContentContainer">
        <h1>Hi, I'm Demi!</h1>
        <p>
          My website is currently in progress, but you can view my work in this{" "}
          <a
            className="AppLink"
            href="https://www.figma.com/proto/3yP19usTPDWM7SOK5YdkZE/Demi-Peppas---Product-design-portfolio?page-id=0%3A1&node-id=508%3A0&viewport=-181%2C345%2C0.08&scaling=contain&starting-point-node-id=508%3A0"
            target="_blank"
            rel="noopener noreferrer"
          >
            Figma prototype
          </a>
          .
        </p>
      </div>
    </div>
  );
}

function General() {
  return (
    <div className="ContentWrapper">
      <div className="ContentContainer">
        <h1>Hi, I'm Demi!</h1>
        <p>
          My website is currently in progress, but feel free to send me an email
          at{" "}
          <a
            className="AppLink"
            href="mailto:demi.peppas@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            demi.peppas@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default App;
